<template>
  <div>
    <v-container
      class="mt-6 px-0"
      :class="$vuetify.breakpoint.mobile ? 'pt-0' : ''"
    >
      <v-row
        align="stretch"
        class="mx-0 flex-lg-row flex-column"
        no-gutters
        :class="$vuetify.breakpoint.mobile ? 'mobile-container' : ''"
      >
        <advanced-pages-sidebar
          :instance="instance"
        />

        <v-col
          cols="12"
          lg="10"
          :class="$vuetify.breakpoint.lgAndDown ? `` : 'pl-12'"
        >
          <router-view
            v-if="pageMounted"
            :instance="instance"
          ></router-view>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AdvancedPagesSidebar from "../../../components/AdvancedPagesSidebar.vue";

export default {
  mounted(){
    this.pageMounted = true;
  },
  data(){
    return {
      pageMounted: false,
    };
  },
  props: {
    instance: Object,
  },
  components: {
    AdvancedPagesSidebar,
  },
};
</script>

<style lang="scss" scoped>
.mobile-container {
  margin-left: -12px;
  margin-right: -12px;
}
</style>